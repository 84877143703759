<template>
    <div>
      <h1>Phone Book</h1>
  
      <form @submit.prevent="addContact">
        <input v-model="newContact.name" placeholder="Name">
        <input v-model="newContact.phone" placeholder="Phone">
        <button type="submit">Add Contact</button>
      </form>
  
      <ul>
        <li v-for="contact in contacts" :key="contact.id">
          {{ contact.name }}: {{ contact.phone }}
          <button @click="deleteContact(contact.id)">Delete</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import { openDB } from 'idb';
  
  export default {
    data() {
      return {
        db: null,
        contacts: [],
        newContact: { name: '', phone: '' },
      };
    },
    async created() {
      this.db = await openDB('my-db', 1, {
        upgrade(db) {
          db.createObjectStore('contacts', { keyPath: 'id', autoIncrement: true });
        },
      });
      this.contacts = await this.db.getAll('contacts');
    },
    methods: {
      async addContact() {
        const plainContact = JSON.parse(JSON.stringify(this.newContact));
        await this.db.add('contacts', plainContact);
        this.contacts = await this.db.getAll('contacts');
        this.newContact = { name: '', phone: '' };
      },
      async deleteContact(id) {
        await this.db.delete('contacts', id);
        this.contacts = await this.db.getAll('contacts');
      },
    },
  };
  </script>
  